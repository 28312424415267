.rs-layer-tree {
  width: 100%;
  overflow-y: auto;

  .rs-layer-tree-arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 0;
    background: transparent;
    width: 5px;
    height: 5px;
  }

  .rs-layer-tree-arrow-collapsed {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-top: -3px;
  }

  .rs-layer-tree-arrow-expanded {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-top: 3px;
  }

  .rs-layer-tree-item {
    display: flex;
    align-items: center;
    padding: 10px;
    position: relative;

    & > * {
      margin-right: 10px;
      align-items: center;
    }

    div {
      /* HACK: Without this it breaks the click on the label (Chrome) */
      position: relative;
    }

    label + div {
      display: inline-block;
    }

    /* CSS for the toggle div */
    .rs-layer-tree-toggle {
      width: auto;
      height: auto;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: left;

      div:first-child {
        margin-right: 10px;
      }
    }

    /* Customize the label (the container) */
    label {
      display: inline-block;
      position: relative;
      cursor: pointer;
    }

    label,
    label input {
      height: 18px;
      min-width: 18px;
    }

    label input,
    label span {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
    }

    label input {
      display: none;
    }

    /* Create a custom checkbox */
    label span {
      background-color: white;
      border: 1px solid lightgray;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
    }

    .rs-layer-tree-input-radio span {
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    label:hover input ~ span {
      background-color: #ccc;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    label span::after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Style the checkmark/indicator */
    .rs-layer-tree-input-checkbox span::after {
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid $brand-primary;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    /* Style the  (dot/circle) */
    .rs-layer-tree-input-radio span::after {
      top: 2px;
      left: 2px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $brand-primary;
    }

    label input:checked ~ span::after {
      display: block;
    }
  }
}
