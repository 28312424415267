.rs-base-layer-toggler {
  width: 120px;
  height: 120px;
  border: 2px solid lightgray;
  background-color: white;
  position: relative;

  .rs-base-layer-map,
  .rs-base-layer-image {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .rs-base-layer-toggle-button {
    width: 100%;
    height: 100%;
  }

  .rs-base-layer-footer {
    background-color: #fffa;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2px;
    height: 21px;
    z-index: 1;

    .rs-base-layer-next,
    .rs-base-layer-previous {
      width: 18px;
      height: 18px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .rs-base-layer-next {
      float: right;
    }

    .rs-base-layer-previous {
      float: left;
    }
  }
}
