.rs-geolocation {
  cursor: pointer;
  height: $btn-size-base;
  width: $btn-size-base;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  color: white;
  background-color: $btn-primary-color;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: $btn-primary-color-hover;
  }

  &.rs-active {
    animation-name: blinking;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  @keyframes blinking {
    0% {
      color: white;
    }

    50% {
      color: $btn-primary-color;
    }

    100% {
      color: white;
    }
  }
}
