.rs-base-layer-switcher {
  position: relative;
  width: 100px;
  transition: 800ms width;
  overflow: hidden;
  display: flex;
  padding: 2px 2px;
  pointer-events: none;

  &.rs-open {
    width: 600px;
  }

  .rs-base-layer-switcher-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 80px;
    width: 100px;
    min-width: 100px;
    margin-right: 4px;
    background-color: rgb(197, 197, 197);
    border: 2px solid white;
    box-sizing: border-box;
    pointer-events: auto;

    &.rs-opener {
      position: absolute;
      top: 2px;       // For IE
      left: 2px;        // For IE
      opacity: 1;
      z-index: 100;
      transition: 700ms opacity, 1000ms z-index;

      &.rs-open {
        opacity: 0;
        z-index: 0;
      }
    }
  }

  .rs-alt-text {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    max-width: 80px;
    overflow: hidden;
  }

  .rs-base-layer-switcher-btn-wrapper {
    display: flex;
    align-items: center;
  }

  .rs-base-layer-switcher-close-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    pointer-events: auto;
  }

  .rs-open {
    &:focus,
    &:hover {
      outline: none;
      border: 2px solid red;
    }
  }

  .rs-base-layer-switcher-title {
    width: 100%;
    max-height: 12px;
    font-size: 10px;
    padding: 2px 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;

    &.rs-active {
      font-weight: bold;
    }
  }
}
