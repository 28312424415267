.rs-zooms-bar {
  position: absolute;
  top: 0;
  right: 0;

  div {
    margin: 10px 0;
  }

  .rs-zoom-in,
  .rs-zoom-out {
    cursor: pointer;
    height: $btn-size-base;
    width: $btn-size-base;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.5s ease, color 0.5s ease;
    border: none;

    &:disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }

  .rs-zoomslider-wrapper {
    display: flex;
    justify-content: center;

    .ol-zoomslider {
      position: relative;
      top: 0;
      left: 0;
      user-select: none;
      background-color: rgba(255, 255, 255, 0.4);
      border: 1px solid $brand-secondary;
      border-radius: 3px;
      outline: none;
      overflow: hidden;
      width: 14px;
      height: 200px;
      margin: 0;

      .ol-zoomslider-thumb {
        user-select: none;
        position: relative;
        display: block;
        background: $brand-secondary;
        outline: none;
        overflow: hidden;
        cursor: pointer;
        font-size: 1.14em;
        height: 20px;
        width: 14px;
        margin: 0;
        padding: 0;
        border: none;
      }
    }
  }
}
