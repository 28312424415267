.rt-route-schedule {
  background-color: white;
  width: 350px;
  overflow: hidden;

  .rt-route-header {
    display: flex;
    align-items: center;
    padding: 15px 10px 0 10px;

    .rt-route-title {
      display: flex;
      flex-direction: column;

      .rt-route-name {
        padding-bottom: 8px;
        font-weight: bold;
      }
    }

    .rt-route-buttons {
      margin-left: auto;
      display: flex;
    }

    .rt-route-icon {
      border-radius: 20px;
      min-width: 20px;
      height: 20px;
      line-height: 20px;
      border: solid black 2px;
      padding: 5px;
      display: block;
      float: left;
      margin: 15px;
      margin-top: 10px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .rt-route-footer {
    padding: 20px;
    display: flex;
    align-items: center;
  }

  .rt-route-copyright {
    display: flex;
    flex-wrap: wrap;
  }

  .rt-route-body {
    font-size: 14px;
    padding: 0 20px;

    .rt-route-station {
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 4px;

      &:first-child,
      &:last-child {
        font-weight: bold;
      }

      &:hover {
        color: white;
        background-color: $brand-secondary;
      }

      .rt-route-times,
      .rt-route-delay {
        display: flex;
        flex-direction: column;
        width: 40px;
        min-width: 40px;
        padding: 0 3px;

        .green {
          color: rgb(0, 160, 12);
        }

        .orange {
          color: rgb(247, 191, 0);
        }

        .light-red {
          color: rgb(255, 74, 0);
        }

        .middle-red {
          color: rgb(232, 0, 0);
        }

        .dark-red {
          color: rgb(237, 0, 76);
        }
      }
    }

    .rt-route-station.rt-passed,
    .rt-route-station.rt-no-stop {
      .rt-route-delay {
        span {
          display: none;
        }
      }
    }

    .rt-route-station.rt-passed {
      opacity: 0.7;

      .rt-route-icon-mask {
        height: 0;
      }
    }

    .rt-route-station:first-child {
      .rt-route-time-arrival {
        display: none;
      }
    }

    .rt-route-station:last-child {
      .rt-route-time-departure {
        display: none;
      }
    }
  }

  .rt-route-cancelled {
    text-decoration: line-through;
    color: rgb(236, 43, 43);
  }
}
