.rt-tracker-control {
  display: flex;
  align-items: center;

  .rt-control-button {
    border: 1px solid #ccc;
    color: #333;
    background-color: #fff;
    padding: 8px 12px 4px 12px;
    margin: 2px;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
      background-color: #f5f5f5;
    }
  }

  .rt-tracker-speed {
    display: flex;
    align-items: center;
    padding-left: 15px;

    svg {
      height: 1.2em;
      width: 1.2em;
      padding-right: 5px;
    }
  }
}
