/**
* This file load the default theme, for all the components.
*/
@import './variables.scss';
@import './mixins.scss';
@import './components.scss';

[role='button']:not([disabled]),
button:not([disabled]),
a:not([disabled]) {
  cursor: pointer;
}
