.rs-north-arrow {
  position: absolute;
  bottom: 5px;
  right: 10px;
  height: 80px;
  width: 80px;

  svg {
    height: 100%;
    width: 100%;
  }
}
