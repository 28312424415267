.rs-popup {
  position: absolute;

  .rs-popup-container {
    position: absolute;
    background-color: white;
    filter: drop-shadow($box-shadow);
    bottom: 12px;
    left: -50px;
    min-width: 220px;

    &::after,
    &::before {
      top: 100%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &::after {
      border-top-color: white;
      border-width: 10px;
      left: 48px;
      margin-left: -10px;
    }

    &::before {
      border-top-color: #ccc;
      border-width: 11px;
      left: 48px;
      margin-left: -11px;
    }

    .rs-popup-close-bt {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rs-popup-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f5f5f5;
      font-weight: bold;
      padding: 10px;
    }

    .rs-popup-body {
      padding: 10px;
    }
  }
}
